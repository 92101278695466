/* CartComponent.css */
.cart-container {
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
    max-height: 500px;
    padding: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 15px;
    overflow: scroll;
  }
  
  .cart-title {
    font-family: 'Poppins-Regular';
    font-size: 22px;
    color: #000000;
    text-align: center;
  }
  
  .cart-subtitle {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    color: #808080;
    margin-bottom: 10px;
  }
  
  .cart-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #616161;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .product-image {
    width: 25%;
    height: 100px;
    object-fit: cover;
  }
  
  .product-details {
    margin-left: 36px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .product-brand {
    font-family: 'Poppins-Regular';
    color: #9E9E9E;
    font-size: 9px;
  }
  
  .product-name {
    font-family: 'Poppins-Regular';
    color: #000000;
  }
  
  .product-regional {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    color: #000000;
  }
  
  .offer-tag {
    position: absolute;
    right: 0;
    top: 0;
    background-color: red;
    color: #fff;
    padding: 5px;
    font-size: 12px;
  }
  
  .price-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .strikethrough-price {
    font-family: 'Montserrat-Regular';
    text-decoration: line-through;
    color: #BDBDBD;
    font-size: 12px;
  }
  
  .discounted-price {
    font-family: 'Montserrat-SemiBold';
    font-size: 16px;
    color: #FF0000;
  }
  
  .remove-button-wrapper {
    margin-left: auto;
    display: flex;
    justify-content: center;
  }
  
  .remove-button {
    color: red;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .navigation-button {
    flex: 1;
    background-color: #1d4e4b;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    color: #ffffff;
    text-align: center;
    margin: 5px;
    cursor: pointer;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  